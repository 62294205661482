import { Link, navigate } from "gatsby";
//import * as React from "react";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { Layout } from '../components';
import { motion } from "framer-motion";
import { API_URL, CHAINS_BY_ID, PAYMENT_ADDRESS, PERMITTED_CHAIN_ID, WEBSITE_URL } from "../constants";
import { ethers } from "ethers";
import { useCookie } from "../components/shared/Utils";


/*********************************
 * Utils
 *********************************/
function lockInput(target_selector) {
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = true;
	e.blur();
}
function lockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		lockInput(target_selectors[i]);
	}
}

function unlockInput(target_selector) {
	if (!target_selector || target_selector.trim() === '') {
		return;
	}
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = false;
	e.blur();
}
function unlockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		unlockInput(target_selectors[i]);
	}
}

function findObjectByProp(arr, prop, val, strict = true) {
	return arr.find((obj) => {
		// eslint-disable-next-line eqeqeq
		return (strict && obj[prop] === val) || (!strict && obj[prop] == val);
	});
}

function showValidationMessage(text, type = 'error', target_selector, add_preload_to_target = false) {
	if (typeof target_selector === 'undefined') {
		alert(text);
	}
	console.log(target_selector);

	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		add_preload_to_target && target.classList.add('has-preloader');
		!add_preload_to_target && target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		target.nextSibling.classList.add(`message-type-${type}`);
		add_preload_to_target && target.nextSibling.classList.add('has-preloader');
		!add_preload_to_target && target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = text;
	}
}

function clearValidationMessage(target_selector) {
	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = '';
	}
}

function handlePresaleStatusSuccess({data, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtists, setArtistSelectOptions}) {
	console.log('Presale status:', {data});
	if (data.result.status === false) {
		window.location.replace(WEBSITE_URL);
		return;
	}

	let presales = data.result.status;

	if (presales.length === 1) {
		setIsPreSale(true);
		setPreSales(presales);

		let artists = [];
		let presale = presales[0];

		// set first presale as selected
		setSelectedPreSaleId(presale.id);

		// add artists from presale
		for (let psd_i = 0, psd_l = presale.presale_drops.length; psd_i < psd_l; psd_i++) {
			let presale_drop = presale.presale_drops[psd_i];

			// set first presale_drop as selected
			if (psd_i === 0) {
				setSelectedDropId(presale_drop.drop_id);
			}

			artists.push({
				id: presale_drop.artist_id,
				name: presale_drop.artist_name,
				maxPerBuyer: presale_drop.max_per_buyer,
				maxSupply: presale_drop.total_supply,
				availableUnits: presale_drop.current_supply,
				price: parseFloat(presale_drop.price),
			})
		}

		console.log('Artists loaded; ', {artists});

		setArtists(artists);

		// if more than 1 artist, add empty option
		/* setArtistSelectOptions((artists.length === 1 ? [] : [<option key="0" value="0">Please choose an artist</option>])
			.concat(data.artists.map((artist) => {
				return <option key={artist.id} value={artist.id}>{artist.name}</option>;
			})
		)); */

		setArtistSelectOptions(artists.map((artist) => {
			return <option key={artist.id} value={artist.id}>{artist.name}</option>;
		}));
	} else if (presales.length > 1) {

		console.warn('⚠️ Multiple pre-sales active; Work In Progress ⚠️');

		setIsPreSale(true);
		setPreSales(presales);

		let artists = [];
		for (let ps_ind = 0, ps_l = 1/* presales.length */; ps_ind < ps_l; ps_ind++) {
			let presale = presales[ps_ind];

			// set first presale as selected
			if (ps_ind === 0) {
				setSelectedPreSaleId(presale.id);
			}

			// add artists from presale
			for (let psd_i = 0, psd_l = presale.presale_drops.length; psd_i < psd_l; psd_i++) {
				let presale_drop = presale.presale_drops[psd_i];

				// set first presale_drop as selected
				if (ps_ind === 0 && psd_i === 0) {
					setSelectedDropId(presale_drop.drop_id);
				}

				artists.push({
					id: presale_drop.artist_id,
					name: presale_drop.artist_name,
					maxPerBuyer: presale_drop.max_per_buyer,
					maxSupply: presale_drop.total_supply,
					availableUnits: presale_drop.current_supply,
					price: parseFloat(presale_drop.price),
				})
			}
		}
		console.log('Artists loaded; ', {artists});
		setArtists(artists);

		setArtistSelectOptions(artists.map((artist) => {
			return <option key={artist.id} value={artist.id}>{artist.name}</option>;
		}));
	} else {
		console.error('Presales list is empty');
	}
}

async function createTransaction({data, userToken, artists}) {
	return await fetch(`${API_URL}/transactions/create`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userToken}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			console.log(data);
			if (data.error) {
				console.log(data.error);
				let error_message;
				switch (data.error) {
					case 'user_not_found':
						error_message = 'User not found';
						break;
					case 'drop_not_found':
						error_message = 'Drop not found';
						break;
					case 'artist_not_found':
						error_message = 'Artist not found';
						break;
					case 'wallet_not_found':
						error_message = 'Wallet not found';
						break;
					case 'amount_unavailable':
						error_message = 'Amount unavailable';
						break;
					case 'max_amount_bought':
						error_message = 'Max amount per user reached';
						break;
					default:
						error_message = 'An error ocurred';
				}
				showValidationMessage(error_message, 'error', '#btn_metamask_pay');
				unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
				return false;
			} else {
				return data.result;
			}
		})
		.catch((error) => {
			console.log(error);
			showValidationMessage(error.message, 'error', '#btn_metamask_pay');
			unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
			return false;
		});
}

async function updateTransaction({tx_id, data, userToken, artists}) {
	return await fetch(`${API_URL}/transactions/update/${tx_id}`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userToken}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			console.log(data);
			if (data.error) {
				console.log(data.error);
				let error_message;
				switch (data.error) {
					case 'user_not_found':
						error_message = 'User not found';
						break;
					case 'drop_not_found':
						error_message = 'Drop not found';
						break;
					case 'artist_not_found':
						error_message = 'Artist not found';
						break;
					case 'wallet_not_found':
						error_message = 'Wallet not found';
						break;
					case 'amount_unavailable':
						error_message = 'Amount unavailable';
						break;
					case 'max_amount_bought':
						error_message = 'Max amount bought';
						break;
					default:
						error_message = 'An error ocurred';
				}
				showValidationMessage(error_message, 'error', '#btn_metamask_pay');
				unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
				return false;
			} else {
				return data.result;
			}
		})
		.catch((error) => {
			console.log(error);
			showValidationMessage(error.message, 'error', '#btn_metamask_pay');
			unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
			return false;
		});
}

async function deleteTransaction({tx_id, userToken, setArtists, artists, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtistSelectOptions}) {
	let result = await fetch(`${API_URL}/transactions/delete/${tx_id}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		})
		.then((response) => response.json())
		.then((data) => {
			console.log('Transaction delete data:', data);
			return data;
		})
		.catch((error) => {
			console.log('Transaction delete error:', error);
			showValidationMessage(error.message, 'error', '#btn_metamask_pay');
			unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
			return false;
		});

	await fetch(`${API_URL}/presale/status/logged`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		})
		.then((response) => response.json())
		.then((data) => handlePresaleStatusSuccess({data, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtists, setArtistSelectOptions}))
		.catch((e) => {
			console.error(e);
		});

	return result;
}

const startPayment = async ({
	txValue,
	addr,
	cId,
	artists,
	selectedArtistId,
	selectedPreSaleId,
	selectedDropId,
	nftUnitQuantity,
	nftUnitPrice,
	nftTotalPrice,
	userToken,
	setArtists,
	setIsPreSale,
	setPreSales,
	setSelectedPreSaleId,
	setSelectedPreSale,
	setSelectedDropId,
	setSelectedDrop,
	setArtistSelectOptions,
}) => {

	try {
		if (!window.ethereum) {
			throw new Error("No crypto wallet found. Please install it.");
		}

		lockInputs(['#btn_metamask_pay', '#artist_name', '#nfts_quantity']);
		showValidationMessage('Sending request for accounts...', 'info', '#btn_metamask_pay', true);
		let accounts;
		try {
			accounts = await window.ethereum.request({
				method: 'eth_requestAccounts',
			});
		} catch (error) {
			console.log(error);
			showValidationMessage(error.message, 'error', '#btn_metamask_pay');
			unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
			return;
		}

		console.log('eth_requestAccounts', accounts);
		clearValidationMessage('#btn_metamask_pay');
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = provider.getSigner();
		ethers.utils.getAddress(addr);

		// TODO: PAYMENT TX
		const { chainId } = await provider.getNetwork();
		console.log('chainId', chainId);
		console.log('permitChain', cId);

		if (chainId === cId) {
			console.log('Wallet is on the right network');
			showValidationMessage('Requesting transaction signature...', 'info', '#btn_metamask_pay', true);

			let userWalletAddress = await signer.getAddress();
			let be_tx = await createTransaction({data: {
				pid: selectedPreSaleId,
				did: selectedDropId,
				aid: selectedArtistId,
				addr: userWalletAddress,
				qty: nftUnitQuantity,
				up: nftUnitPrice,
				tp: nftTotalPrice,
			}, userToken, artists});

			console.log({be_tx});

			if (be_tx === false) {
				unlockInputs(['#btn_metamask_pay', '#artist_name', '#nfts_quantity']);
				return;
			}

			fetch(`${API_URL}/presale/status/logged`, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userToken}`,
					},
				})
				.then((response) => response.json())
				.then((data) => handlePresaleStatusSuccess({data, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtists, setArtistSelectOptions}))
				.catch((e) => {
					console.error(e);
				});

			let tx;
			try {
				tx = await signer.sendTransaction({
					to: addr,
					from: userWalletAddress,
					value: ethers.utils.parseEther(txValue),
				});
			} catch (error) {
				console.log(error);
				await deleteTransaction({tx_id: be_tx.id, userToken, setArtists, artists, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtistSelectOptions});
				showValidationMessage(error.message, 'error', '#btn_metamask_pay');
				unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
				return;
			}

			tx.wait(1)
				.then((receipt) => {
					console.log(receipt);
					be_tx = updateTransaction({tx_id: be_tx.id, data: {h: tx.hash, sid: 2}, userToken, artists});
					console.log(be_tx);
					showValidationMessage(`Transaction confirmed with <span style="font-family:Secondary-Bold">${receipt.confirmations} confirmation${receipt.confirmations !== 1 ? 's' : ''}</span>!<br />Redirecting you to your client area...`, 'success', '#btn_metamask_pay');
					setTimeout(() => {
						navigate('/client-area');
					}, 4000);
				})
				.catch((error) => {
					console.log(error);
					deleteTransaction({tx_id: be_tx.id, userToken, setArtists, artists, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedDropId, setArtistSelectOptions});
					showValidationMessage(error.message, 'error', '#btn_metamask_pay');
					unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
					return;
				});

			let network;
			switch (chainId) {
				case 3:
					network = 'ropsten.';
					break;
				case 4:
					network = 'rinkeby.';
					break;
				case 5:
					network = 'gorli.';
					break;
				case 1:
				default:
					network = '';
			}

			const txLink = 'https://' + network + 'etherscan.io/tx/' + tx.hash;

			showValidationMessage(`<div class="tx-info">
										<div class="tx-title">Your transaction hash:</div>
										<div class="tx-hash" title="${tx.hash}">${tx.hash}</div>
										<a class="tx-hash-link" href="${txLink}" target="_blank">View on Etherscan</a>
									</div>`, 'info', '#btn_metamask_pay');

		} else {
			unlockInputs(['#btn_metamask_pay', artists.length > 1 ? '#artist_name' : '', '#nfts_quantity']);
			showValidationMessage(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[cId]}'`, 'warning', '#btn_metamask_pay');
			console.log(`You need to change your 🦊 Metamask network to Ethereum Mainnet`);
		}

	}
	catch (err) {
		unlockInput('#btn_metamask_pay');
		showValidationMessage(err.message, 'error', '#btn_metamask_pay');
	}
};


// markup
const PreSale = ({ location }) => {

	const isMobileDevice = useRef(false); /* Check if mobile device */
	const [isPreSale, setIsPreSale] = useState(null);
	const [walletChainId, setWalletChainId] = useState(0);
	const [hasAccount, setHasAccount] = useState(false);
	const [isLogged, setIsLogged] = useState(false);
	const [isWhitelisted, setIsWhitelisted] = useState(null);
	const [isPrevWhitelisted, setIsPrevWhitelisted] = useState(null);
	const [userEmailAddress, setUserEmailAddress] = useState('');
	const [userWalletAddress, setUserWalletAddress] = useState(null);
	const [userToken, setUserToken] = useCookie('userToken', false);
	const [preSales, setPreSales] = useState(null);
	const [selectedPreSaleId, setSelectedPreSaleId] = useState(null);
	const [selectedPreSale, setSelectedPreSale] = useState(null);
	const [selectedDropId, setSelectedDropId] = useState(null);
	const [selectedDrop, setSelectedDrop] = useState(null);
	const [artists, setArtists] = useState(null);
	const [artistSelectOptions, setArtistSelectOptions] = useState(null);
	const [availableUnits, setAvailableUnits] = useState(0);
	const [totalUnits, setTotalUnits] = useState(0);


	/*********************************
	 * Check if Mobile Device
	 *********************************/
	useEffect(() => {
		/* Detect if mobile device */
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			// true for mobile device
			isMobileDevice.current = true;
		} else {
			// false for not mobile device
			isMobileDevice.current = false;
		}
	});


	/*********************************
	 * Check if Pre-Sale
	 *********************************/
	useEffect(() => {
		console.clear();
		console.log('Page loaded; starting by checking if there is an active pre-sale...');

		/* Fetching the status of the presale. */
		fetch(`${API_URL}/presale/status`)
			.then((response) => response.json())
			.then((data) => {
				console.log('INITIAL Presale status:', {data});
				if (data.result.status === false) {
					window.location.replace(WEBSITE_URL);
					return;
				}

				setIsPreSale(true);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);


	/*********************************
	 * Check token validity
	 *********************************/
	useEffect(() => {
		console.log({userToken});

		// if we have a token, check if it's valid
		if (userToken != null && userToken !== false && userToken !== 'false' && isLogged !== true && hasAccount !== true) {
			fetch(`${API_URL}/auth/me`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					console.log('Token valid; data:', data);
					// if we get a user wallet
					if (data.user && data.user.wallets && data.user.wallets.length) {
						// if we have the user's email
						if (data.user.email) {
							setUserEmailAddress(data.user.email);
						}
						setUserWalletAddress(data.user.wallets[0].address);
						setIsLogged(true);
						setHasAccount(true);
						setIsWhitelisted(data.user.whitelisted);
						setIsPrevWhitelisted(data.user.prev_whitelisted);

						// Refreshing presales to include special ones, if available
						fetch(`${API_URL}/presale/status/logged`, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${userToken}`,
							},
						})
						.then((response) => response.json())
						.then((data) => handlePresaleStatusSuccess({data, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtists, setArtistSelectOptions}))
						.catch((e) => {
							console.error(e);
						});
					} else {
						console.log('Insufficient data, require re-login');
						setUserToken(false);
					}
				})
				.catch((error) => {
					console.error(error);
					setUserToken(false);
				});
		}
	}, [userToken]);


	/*********************************
	 * Check account existence
	 *********************************/
	useEffect(() => {
		console.log('Check account existence:', {userWalletAddress, isLogged, hasAccount, userToken, "(userToken != null && userToken !== false && userToken !== 'false')": (userToken != null && userToken !== false && userToken !== 'false')});

		if (userWalletAddress == null || isLogged || hasAccount || (userToken != null && userToken !== false && userToken !== 'false')) {
			return;
		}

		// Fetch à API para verificar se utilizador já tem conta
		fetch(`${API_URL}/user/check/wallet/${userWalletAddress}`)
			.then((response) => response.json())
			.then((data) => {
				if (!data.result.valid) {
					throw new Error(`${userWalletAddress} is not a valid address!`);
				}

				setHasAccount(!!data.result.exists);
				setIsLogged(!!data.result.activated);

				if (data.result.exists) {
					setUserEmailAddress(data.result.email);
					setUserWalletAddress(data.result.wallet);

					if (data.result.activated) {
						setIsWhitelisted(data.result.whitelisted);
						setIsPrevWhitelisted(data.result.prev_whitelisted);
						loginOnLoad(data.result.wallet);
					}
				} else {
					setArtists(false);
				}
			})
			.catch((e) => console.error(e));

	}, [userWalletAddress, hasAccount, isLogged, userToken]);

	function loginOnLoad(walletAddress) {
		showValidationMessage('Requesting Metamask signature...', 'info', '#btn_metamask_login');
		// sign message to get unique signature
		try {
			window.ethereum.request({
				method: 'personal_sign',
				params: [
					walletAddress,
					'🔐  Login @ EphemeralEthernal.com',
				],
			})
			.then((signature) => {
				console.log('personal_sign response:', {signature, walletAddress});
				showValidationMessage('Signing in with Metamask...', 'info', '#btn_metamask_login');

				fetch(`${API_URL}/auth/login`, {
					method: 'POST',
					body: JSON.stringify({
						wallet: walletAddress,
						signature: signature,
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						console.log('Login response:', response);

						return response.json();
					})
					.then((data) => {
						console.log('Login data:', data);

						if (data.error) {
							console.log('Login error:', data.error);
							showValidationMessage(data.error.message, 'error', '#btn_metamask_login');
							setIsLogged(false);
						} else if (data.errors) {
							console.log('Login errors:', data.errors);
							showValidationMessage(data.errors.join('<br>'), 'error', '#btn_metamask_login');
							setIsLogged(false);
						} else {
							console.log('Saving userToken:', {userToken, 'data.token': data.token});
							setUserToken(data.token);
							console.log('Setting isLogged');
							setIsLogged(true);

							// Refreshing presales to include special ones, if available
							fetch(`${API_URL}/presale/status/logged`, {
									method: 'GET',
									headers: {
										Authorization: `Bearer ${data.token}`,
									},
								})
								.then((response) => response.json())
								.then((data) => handlePresaleStatusSuccess({data, setIsPreSale, setPreSales, setSelectedPreSaleId, setSelectedPreSale, setSelectedDropId, setSelectedDrop, setArtists, setArtistSelectOptions}))
								.catch((e) => {
									console.error(e);
								});
						}
					})
					.catch((error) => {
						showValidationMessage('Error logging in.', 'error', '#btn_metamask_login');
						console.error('Error logging in:', error);
					});
			})
			.catch((e) => {
				console.error('User rejected signing:', e);
				showValidationMessage('🦊&nbsp;&nbsp;Please, sign the request to login with Metamask <span style="font-family:Secondary-Bold">no tx cost</span>.', 'error', '#btn_metamask_login');
				return;
			});
		} catch(error) {
			console.error('Error requesting sign:', error);
			showValidationMessage('Error requesting sign.', 'error', '#btn_metamask_login');
		}
	}


	/*********************************
	 * Listen for chainChanged event
	 *********************************/
	useEffect(() => {
		if (window.ethereum) {
			// detect Metamask account change
			/* window.ethereum.on('accountsChanged', function (accounts) {
				console.log('=====> accountsChanges',accounts);
			}); */

			/* When the user changes the network in Metamask, we want to update the wallet's chainId. */
			window.ethereum.on('chainChanged', function(networkId){
				console.log('=====> chainChanged',networkId);

				setWalletChainId( parseInt(window.ethereum.chainId, 16) );

				/* if (parseFloat(networkId) === PERMITTED_CHAIN_ID) {
					console.log('Wallet is on the right network');
				} else {
					console.log(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`);
				} */
			});
		} else {
			console.warn('No window.ethereum; chainChanged event NOT registered');
		}
	}, []);


	/*********************************
	 * Verify Wallet chain and prompt for connection to Metamask
	 *********************************/
	useEffect(() => {
		console.log('Verify Wallet chain and prompt for connection to Metamask');
		console.log({isPreSale});
		if (isPreSale !== true) {
			return;
		}

		async function promptForConnect() {
			if (window.ethereum) {
				// prompt for connect on enter and save user's first wallet address
				let accounts = await window.ethereum.request({
					method: 'eth_requestAccounts',
				});
				if (accounts && accounts.length) {
					setUserWalletAddress(accounts[0]);
				}

				let _walletChainId = parseInt(window.ethereum.chainId, 16);
				setWalletChainId(_walletChainId);

				if (_walletChainId === PERMITTED_CHAIN_ID) {
					console.log('Wallet is on the right network');
				} else {
					console.log(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`);
				}
			}
		};

		promptForConnect();
	}, [isPreSale]);


	/*********************************
	 * Pre-Sale ARTIST & NFT units
	 *********************************/
	const [selectedArtistId, setSelectedArtistId] = useState('0');
	const [selectedArtist, setSelectedArtist] = useState(null);
	const [nftQuantitiesSelectOptions, setNftQuantitiesSelectOptions] = useState([<option key="0" value="0">0</option>]);
	const [nftUnitPrice, setNftUnitPrice] = useState('0');
	const [nftUnitQuantity, setNftUnitQuantity] = useState('0');
	const [nftTotalPrice, setNftTotalPrice] = useState('0');

	function updateNftQuantitiesAndPrice(target) {
		console.log('updateNftQuantitiesAndPrice:', {target});
		if (target == null) {
			return;
		}

		let artist = findObjectByProp(artists, 'id', parseInt(target.value));
		setSelectedArtist(artist);

		console.log('Selected artist for id "', parseInt(target.value), '":', artist);

		if (artist == null) {
			setNftQuantitiesSelectOptions([<option key="0" value="0">0</option>]);
			setAvailableUnits(0);
			setTotalUnits(0);
			setNftUnitPrice(0);
			setNftTotalPrice(0);
			lockInput('#btn_metamask_pay');
			return;
		}
		setSelectedArtistId(target.value);
		unlockInput('#btn_metamask_pay');

		let temp = [];
		setNftUnitPrice(artist.price);
		for (let i = 1; i <= Math.min(artist.maxPerBuyer, artist.availableUnits); i++) {
			if (i === 1) {
				setNftUnitQuantity(1);
			}
			temp.push(<option key={i} value={i}>{i}</option>);
		}
		setNftQuantitiesSelectOptions(temp);
		setNftTotalPrice(artist.price);
		setAvailableUnits(artist.availableUnits);
		setTotalUnits(artist.maxSupply);
	}

	const artistSelect = useCallback((node) => {
		console.log('artistSelect: ', {node});
		if (node != null) {
			updateNftQuantitiesAndPrice(node.options[node.selectedIndex]);
		}
	}, [artistSelectOptions]);

	function onChangeArtist(e) {
		updateNftQuantitiesAndPrice(e.target.options[e.target.selectedIndex]);
	}

	function onChangeQuantity(e) {
		//console.log(e.target.value);
		setNftUnitQuantity(e.target.value);
		setNftTotalPrice(e.target.value * nftUnitPrice);
	}


	/*********************************
     * Pay with Metamask
     *********************************/

	const handlePayment = async (e) => {
		e.preventDefault();
		startPayment({
			txValue: '' + nftTotalPrice,
			addr: PAYMENT_ADDRESS,
			cId: PERMITTED_CHAIN_ID,
			artists,
			selectedArtistId,
			selectedPreSaleId,
			selectedDropId,
			nftUnitQuantity,
			nftUnitPrice,
			nftTotalPrice,
			userToken,
			setArtists,
			setIsPreSale,
			setPreSales,
			setSelectedPreSaleId,
			setSelectedPreSale,
			setSelectedDropId,
			setSelectedDrop,
			setArtistSelectOptions,
		});
	};


	return (
		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/login.css" rel="stylesheet" type="text/css" />
			</Helmet>


			<motion.main
				id='pre_sale'
				style={{ opacity: 0 }}
				data-name="layout"

				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{
					type: "spring",
					mass: 0.35,
					stiffness: 75,
					duration: 0.5,
					delay: 1
				}}
			>
				<div className={'main-wrapper'}>

					<motion.div
						style={{ opacity: 0 }}

						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{
							type: "spring",
							mass: 0.35,
							stiffness: 75,
							duration: 0.5,
							delay: 0.3
						}}
					>
						{preSales !== null ? (
							<div className={'bg-img'}>
								{preSales[0].banner_video_paths ? (
									isMobileDevice.current ? (
										<video className={'media'} muted loop poster={preSales[0].banner_poster_paths ? preSales[0].banner_poster_paths[750] : null}>
											<source src={(typeof preSales[0].banner_video_paths) === 'string' ? preSales[0].banner_video_paths : null} type="video/mp4" />
											This browser does not support the HTML5 video element.
										</video>
									) : (
										<video className={'media'} muted autoPlay loop poster={preSales[0].banner_poster_paths ? preSales[0].banner_poster_paths[1920] : null}>
											<source src={(typeof preSales[0].banner_video_paths) === 'string' ? preSales[0].banner_video_paths : null} type="video/mp4" />
											This browser does not support the HTML5 video element.
										</video>
									)
								) : (null)}

								<div className={'bg-fade'}></div>
							</div>
						) : null}

						<div className={'pre-sale-bg d-flex justify-content-center align-items-center'}>
							<div className={'pre-sale-hero container-fluid'}>
								<div className={'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3'}>
									<div className={'row align-items-center'}>
										<div className={'title col-6'}>Pre-<br />sale</div>
										<div className={'sign-in col-6'}>Get early access to our artists' exclusive NFT artworks</div>
									</div>
								</div>
							</div>
						</div>
					</motion.div>


					{/* If Mobile Device */}
					{isMobileDevice.current && window?.ethereum?.isMetaMask === undefined ? (
						<div className={'container-fluid'}>
							<div className={'info-container col-sm-10 offset-sm-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'}>
								<div>
									<img src={'/assets/img/icons/info_icon.svg'} alt={'info-icon'} />
								</div>
								<div>While using a mobile device, you will have a better experience by using Metamask browser to connect directly. </div>
							</div>
						</div>
					) : null}


					{isLogged === false && hasAccount ? (

						<motion.div
							style={{ opacity:0 }}

							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{
								type: "spring",
								mass: 0.35,
								stiffness: 75,
								duration: 0.5,
								delay: 0.3
							}}
						>

							{/* WAITING CALLBACK */}
							<div className={'gradient loading container-fluid'}>
								<div className={'py-5 text-center col-12'}>
									Awaiting your Metamask confirmation to login...
								</div>
								<div className="metamask-container my-5 col-10 col-lg-3">
									<button id="btn_metamask_login" className={'btn btn--primary'} onClick={ () => loginOnLoad(window.ethereum.selectedAddress) } disabled={ !window.ethereum }>
										<img src={'/assets/img/metamask/metamask_logo.svg'} alt={'siteTitle'} />
										<div>Login with<br /><span>METAMASK</span></div>
										<div></div>
									</button>
									<div className="validation-message"></div>
								</div>
							</div>
						</motion.div>

					) : ( isLogged == null || isPreSale == null || artists == null ? (

							<motion.div
								style={{ opacity: 0 }}

								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									type: "spring",
									mass: 0.35,
									stiffness: 75,
									duration: 0.5,
									delay: 0.3
								}}
							>
								{/* WAITING CALLBACK */}
								<div className={'gradient loading container-fluid'}>
									<div className={'py-5 text-center col-12'}>
										Loading...
									</div>
								</div>
							</motion.div>

						) : (
							isWhitelisted || isPrevWhitelisted ? (

								<motion.div
									className={'buy-form my-5 pb-5'}
									style={{ opacity: 0 }}

									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{
										type: "spring",
										mass: 0.35,
										stiffness: 75,
										duration: 0.5
									}}
								>
									<div className={'container-fluid d-flex justify-content-center align-items-center flex-column mb-5'}>
										<div className={'col-10 col-sm-8 col-md-6 col-xl-4'}>

											<div className={'row mb-2'}>

												<div className={'presale col-12'}>
													<input type={'hidden'} name={'pid'} value={selectedPreSaleId} />
													<input type={'hidden'} name={'did'} value={selectedDropId} />
												</div>
												<div className={'artist col-12 mb-4'}>
													<div className={'label'}>Pre-sale artist{artists.length === 1 ? '' : 's'}</div>

													<select id={'artist_name'} ref={artistSelect} onChange={onChangeArtist} className={'custom-select'} name={'artist_name'} disabled={artists.length === 1}>
														{artistSelectOptions}
													</select>
												</div>

												{totalUnits > 0 ? (
													<>
														<div className={'nfts col-12 mb-4'}>
															<div className={'label'}>
																NFT units available
															</div>
															<div className={'available-units'}>
																<span className={'units'}>{availableUnits}</span>/{totalUnits}
															</div>
														</div>

														{availableUnits > 0 ? (
															<>
																<div className={'quantity col-6 mb-4'}>
																	<div className={'label'}>Choose quantity</div>
																	<select id={'nfts_quantity'} onChange={onChangeQuantity} className={'custom-select'} name={'nfts_quantity'}>
																		{nftQuantitiesSelectOptions}
																	</select>
																</div>

																<div className={'total-to-pay col-6 mb-4'}>
																	<div className={'label'}>
																		Total to pay:
																	</div>
																	<div className={'total'}>
																		<span className={'price'}>{nftTotalPrice}</span> <span className={'coin'}>ETH</span>
																	</div>
																</div>
															</>
														) : (
															<div className="unavailable col-12">
																No more pre-sale units available for this artist.<br />
																Please visit our website catalog.
															</div>
														)}
													</>
												) : null}

											</div>

											{totalUnits > 0 && availableUnits > 0 ? (
												<div className={'metamask-container col-12'}>

													{window.ethereum && walletChainId !== 0 && walletChainId !== PERMITTED_CHAIN_ID ? (
														<div className="chain-message">{`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`}</div>
													) : null}

													<button id="btn_metamask_pay" className={'btn btn--primary'} onClick={ handlePayment } disabled={!window.ethereum || walletChainId !== PERMITTED_CHAIN_ID}>
														<img src={'/assets/img/metamask/metamask_logo.svg'} alt={'Metamask'} />
														{window.ethereum ? (
															<>
																<div>Pay with<br /><span>METAMASK</span></div>
																<div></div>
															</>
														) : (
															<>
																<div>Please install<br /><span>METAMASK</span></div>
																<div className={'d-none'}></div>
															</>
														)}
													</button>

													<div className="validation-message"></div>

													{!window.ethereum ? (
														<a className={'download-metamask-url col-12'} href={'https://metamask.io/download.html'} target={'_blank'} rel="noreferrer">
															https://metamask.io/download.html
														</a>
													) : null}

												</div>
											) : null}

											<div className={'skip-to-client-area'}>
												Not interessted in this pre-sale?<br />
												Skip to your <Link to={'/client-area/'}>Client Area</Link>
											</div>

										</div>
									</div>
								</motion.div>

							) : (

								<div className={'skip-to-client-area container-fluid flex-column my-5'}>
									<div className={'text-center col-12 py-5'}>
										There's a Pre-sale active right now but you did not signup to the Whitelist.<br/>
										No problem, you'll get the next one.
										<br/><br/>
										Skip to your <Link to={'/client-area/'}>Client Area</Link>
									</div>
								</div>

							)
						)
					) }


				</div>

			</motion.main>

		</Layout >
	)
}

export default PreSale
